<div style="display: flex; align-items: start; flex-direction: row; justify-content: space-evenly">
  <div
    style="padding: 16px; display: flex; flex-direction: column; align-items: start; border-radius: 8px"
    *ngIf="
      validOnboarding &&
      this.tenants.length > 0 &&
      !isCurrentlyCreatingOrganization &&
      !newOrganizationCreated &&
      !newOrganizationFailed
    "
  >
    <div *ngIf="currentlySelected === undefined">
      <div fxLayout="row" fxLayoutAlign="space-between" style="height: 50%">
        <p-card header="Cloud Control Onboarding" subheader="Create your new Organization">
          <ng-template pTemplate="header">
            <div class="flex flex-col justify-content-center align-items-center">
              <img
                alt="header_cube"
                src="../../../../../assets/header_img.jpeg"
                style="border-top-left-radius: 8px; border-top-right-radius: 8px"
              />
            </div>
          </ng-template>
          <div class="flex flex-col">
            <div style="margin-bottom: 10px">
              <p-messages severity="info">
                <ng-template pTemplate>
                  <i class="pi pi-info-circle" style="margin-right: 5px"></i>
                  <div class="m1-2">
                    Enter the name of your new organization. <i>This name can not be changed afterwards!</i>
                  </div>
                </ng-template>
              </p-messages>
            </div>
            <form [formGroup]="dataFormGroup">
              <div class="w-full" style="margin-top: 32px">
                <p-floatLabel>
                  <input type="text" formControlName="orgName" pInputText id="org" [style]="{ width: '400px' }" />
                  <label for="org">Organization Name</label>
                </p-floatLabel>
                <small *ngIf="dataFormGroup.controls['orgName'].invalid" severity="error" class="error-text">
                  {{ getErrorMessage() }}
                </small>
              </div>
              <div class="w-full" style="margin-top: 32px">
                <p-floatLabel>
                  <input
                    type="text"
                    formControlName="licenseKey"
                    pInputText
                    id="licenseKey"
                    [style]="{ width: '400px' }"
                  />
                  <label for="licenseKey">License Key</label>
                </p-floatLabel>
                <small *ngIf="dataFormGroup.controls['licenseKey'].invalid" severity="error" class="error-text">
                  Invalid License Key format
                </small>
              </div>
            </form>
            <div style="margin-top: 20px">
              <p-messages severity="info">
                <ng-template pTemplate>
                  <i class="pi pi-info-circle" style="margin-right: 5px"></i>
                  <div class="m1-2">
                    Connect at least one of your tenants to Cloud Control.
                    <i>You need Admin access in that tenant to complete this process.</i>
                  </div>
                </ng-template>
              </p-messages>
            </div>
            <div>
              <p-table
                [value]="tenants"
                [tableStyle]="{ 'min-widht': '60rem' }"
                styleClass="p-datatable-striped"
                [paginator]="tenants.length > 5"
                [rows]="5"
                [rowsPerPageOptions]="[5, 10, 20]"
              >
                <ng-template pTemplate="caption">
                  <div class="flex align-items-center justify-content-between">Tenants</div>
                </ng-template>
                <ng-template pTemplate="header">
                  <tr>
                    <th>Name</th>
                    <th>Connected</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-tenant let-columns="columns">
                  <tr>
                    <td>{{ tenant.displayName }}</td>
                    <td>
                      <div *ngIf="tenant.consent"><i class="pi pi-check"></i>Connected</div>
                      <div *ngIf="!tenant.consent">
                        <p-button
                          label="Connect"
                          icon="pi pi-plus"
                          severity="secondary"
                          (onClick)="selectedTenant(tenant)"
                        ></p-button>
                      </div>
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="summary">
                  <div class="flex align-items-center justify-content-between">
                    There are {{ tenants ? tenants.length : 0 }} Tenants available.
                  </div>
                </ng-template>
              </p-table>
            </div>
          </div>
          <ng-template pTemplate="footer">
            <div class="flex gap-3 mt-1">
              <p-button
                [disabled]="!atLeastOneConnected"
                label="Create"
                icon="pi pi-plus"
                severity="primary"
                class="w-full"
                styleClass="w-full"
                (click)="finishOnboarding()"
              />
            </div>
          </ng-template>
        </p-card>
      </div>
    </div>
    <!--End  Onboarding default Screen-->
    <div *ngIf="currentlySelected !== undefined">
      <p-card header="Onboarding" subheader="Create Organization/Connect {{ currentlySelected.displayName }} tenant">
        <ng-template pTemplate="header">
          <div class="flex flex-col justify-content-center align-items-center">
            <img
              alt="header_cube"
              src="../../../../../assets/header_img.jpeg"
              style="border-top-left-radius: 8px; border-top-right-radius: 8px"
            />
          </div>
        </ng-template>
        <div class="flex flex-col">
          <div class="font-small" style="padding-top: 2rem; padding-bottom: 2rem; height: fit-content">
            <p-messages severity="info">
              <ng-template pTemplate>
                <i class="pi pi-info-circle" style="margin-right: 5px"></i>
                <div class="m1-2">
                  Consent to two Enterprise Applications to be created in this tenant. A new window will open where you
                  can give the admin consent for this application.
                </div>
              </ng-template>
            </p-messages>
            <p-messages severity="info">
              <ng-template pTemplate>
                <i class="pi pi-info-circle" style="margin-right: 5px"></i>
                <div class="m1-2">
                  This requires certain permissions. If you do not have these permissions you can copy the two links
                  below so someone else can give the consent.
                </div>
              </ng-template>
            </p-messages>
            <div class="font-small"></div>
          </div>
          <div style="margin-top: 20px; margin-bottom: 20px">
            <p-button
              [pTooltip]="backTooltipContent"
              tooltipPosition="top"
              label="Back"
              severity="secondary"
              showDelay="1000"
              hideDelay="300"
              (click)="cancel()"
              icon="pi pi-angle-double-left"
              iconPos="left"
              (onClick)="cancel()"
            >
            </p-button>
            <ng-template #backTooltipContent>
              <div class="flex align-items-center">
                <span>Go back to main menu</span>
              </div>
            </ng-template>
          </div>

          <!--THE STEPPER DOES NO LONGER WORK!-->
          <!--TODO: Replace-->
          <p-button
            *ngIf="!frontendConsent"
            label="Consent to Application"
            iconPos="right"
            pTooltip="Consent to Application"
            tooltipPosition="top"
            showDelay="1000"
            hideDelay="300"
            icon="pi pi-arrow-right"
            (onClick)="consentToApplicationLink('portal')"
          />
          <p-button
            label="Create Service Principal"
            iconPos="right"
            icon="pi pi-arrow-right"
            pTooltip="Create a Service Principal"
            showDelay="1000"
            hideDelay="300"
            tooltipPosition="top"
            [loading]="isCurrentlyCreatingApp"
            (onClick)="createServicePrincipal()"
          />
          <p-button
            *ngIf="isAppCreated"
            label="Consent to Service Principal"
            iconPos="right"
            pTooltip="Consent to Service Principal"
            tooltipPosition="top"
            showDelay="1000"
            hideDelay="300"
            icon="pi pi-arrow-right"
            (onClick)="openApplicationPermissionsConsent()"
          />
          <p-button
            *ngIf="applicationConsent"
            label="Consent to Cloud Control Portal"
            iconPos="right"
            pTooltip="Consent to Cloud Control Portal"
            showDelay="1000"
            hideDelay="300"
            tooltipPosition="top"
            icon="pi pi-arrow-right"
            (onClick)="consentToApplicationLink('prod')"
          />
          <div>
            <div class="flex-colum flex font-small">
              <h4><i class="pi pi-info-circle"></i> Configuration:</h4>
              <ul>
                <li>
                  Organization Name: <p-chip>{{ dataFormGroup.controls.orgName.value }}</p-chip>
                </li>
                <li>
                  Tenant Name: <p-chip>{{ currentlySelected.displayName }}</p-chip>
                </li>
              </ul>
            </div>
            <p-button label="Connect Tenant" severity="success" icon="pi pi-check" (onClick)="finishTenant()" />
            <p-button
              label="Cancel"
              severity="danger"
              (click)="cancel()"
              icon="pi pi-times"
              iconPos="left"
              (onClick)="cancel()"
            />
          </div>
          <!--ENDE!-->

          <!--<div class="card flex justify-content-center">
            <p-stepper orientation="vertical" [linear]="true" [(activeStep)]="activeIndex">
              <p-stepperPanel header="Consent to Application">
                <ng-template pTemplate="content" let-nextCallback="nextCallback" let-index="index">
                  <div class="flex flex-column h-12rem">
                    <div style="margin-top: 10px; margin-bottom: 10px">
                      <p-button
                        *ngIf="!frontendConsent"
                        label="Consent to Application"
                        iconPos="right"
                        pTooltip="Consent to Application"
                        tooltipPosition="top"
                        showDelay="1000"
                        hideDelay="300"
                        icon="pi pi-arrow-right"
                        (onClick)="consentToApplicationLink('portal')"
                      />
                    </div>
                  </div>
                </ng-template>
              </p-stepperPanel>
              <p-stepperPanel header="Create a Service Principal">
                <ng-template
                  pTemplate="content"
                  let-prevCallback="prevCallback"
                  let-nextCallback="nextCallback"
                  let-index="index"
                >
                  <div class="flex flex-column h-12rem">
                    <div
                      class="border-2 border-dashed border-round surface-ground flex-auto flex justify-content-center align-items-center font-medium"
                    >
                      <div style="margin-top: 10px; margin-bottom: 10px">
                        <p-button
                          label="Create Service Principal"
                          iconPos="right"
                          icon="pi pi-arrow-right"
                          pTooltip="Create a Service Principal"
                          showDelay="1000"
                          hideDelay="300"
                          tooltipPosition="top"
                          [loading]="isCurrentlyCreatingApp"
                          (onClick)="createServicePrincipal()"
                        />
                      </div>
                    </div>
                  </div>
                </ng-template>
              </p-stepperPanel>
              <p-stepperPanel header="Consent to Service Principal">
                <ng-template
                  pTemplate="content"
                  let-prevCallback="prevCallback"
                  let-nextCallback="nextCallback"
                  let-index="index"
                >
                  <div class="flex flex-column h-12rem">
                    <div
                      class="border-2 border-dashed border-round surface-ground flex-auto flex justify-content-center align-items-center font-medium"
                    >
                      <div style="margin-top: 10px; margin-bottom: 10px">
                        <p-button
                          *ngIf="isAppCreated"
                          label="Consent to Service Principal"
                          iconPos="right"
                          pTooltip="Consent to Service Principal"
                          tooltipPosition="top"
                          showDelay="1000"
                          hideDelay="300"
                          icon="pi pi-arrow-right"
                          (onClick)="openApplicationPermissionsConsent()"
                        />
                      </div>
                    </div>
                  </div>
                </ng-template>
              </p-stepperPanel>
              <p-stepperPanel header="Consent to Cloud Control Portal">
                <ng-template
                  pTemplate="content"
                  let-index="index"
                  let-prevCallback="prevCallback"
                  let-nextCallback="nextCallback"
                >
                  <div class="flex flex-column h-12rem">
                    <div
                      class="border-2 border-dashed border-round surface-ground flex-auto flex justify-content-center align-items-center font-medium"
                    >
                      <div style="margin-top: 10px; margin-bottom: 10px">
                        <p-button
                          *ngIf="applicationConsent"
                          label="Consent to Cloud Control Portal"
                          iconPos="right"
                          pTooltip="Consent to Cloud Control Portal"
                          showDelay="1000"
                          hideDelay="300"
                          tooltipPosition="top"
                          icon="pi pi-arrow-right"
                          (onClick)="consentToApplicationLink('prod')"
                        />
                      </div>
                    </div>
                  </div>
                </ng-template>
              </p-stepperPanel>
              <p-stepperPanel header="Connect Tenant">
                <ng-template pTemplate="content" let-index="index" let-prevCallback="prevCallback">
                  <div class="flex flex-column h-12rem">
                    <div
                      class="border-2 border-dashed border-round surface-ground flex-auto flex justify-content-center align-items-center font-medium"
                    >
                      <div class="flex-colum flex font-small">
                        <h4><i class="pi pi-info-circle"></i> Configuration:</h4>
                        <ul>
                          <li>
                            Organization Name: <p-chip>{{ dataFormGroup.controls.orgName.value }}</p-chip>
                          </li>
                          <li>
                            Tenant Name: <p-chip>{{ currentlySelected.displayName }}</p-chip>
                          </li>
                        </ul>
                      </div>
                      <p-button
                        label="Connect Tenant"
                        severity="success"
                        icon="pi pi-check"
                        (onClick)="finishTenant()"
                      />
                      <p-button
                        label="Cancel"
                        severity="danger"
                        (click)="cancel()"
                        icon="pi pi-times"
                        iconPos="left"
                        (onClick)="cancel()"
                      >
                      </p-button>
                    </div>
                  </div>
                </ng-template>
              </p-stepperPanel>
            </p-stepper>
          </div>-->
        </div>
      </p-card>
    </div>
  </div>

  <!-- Onboarding Default Screen  -->
  <div *ngIf="isCurrentlyCreatingOrganization">
    <div style="height: 90vh; display: flex; align-items: center; justify-content: center">
      <div style="display: flex; flex-direction: column; align-items: center">
        <div class="card flex justify-content-center">
          <p-progressSpinner styleClass="w-4rem h-4rem" strokeWidth="2" animationDuration=".5s" />
        </div>
        <div style="margin-top: 8px">Please wait while we are creating your new organization...</div>
      </div>
    </div>
  </div>
  <div *ngIf="newOrganizationCreated" class="created-text">
    <div>
      <p-messages severity="success">
        <ng-template pTemplate>
          <i class="pi pi-check-circle" style="margin-right: 5px"></i>
          <div class="m1-2">
            Your organization {{ dataFormGroup.controls.orgName.value }} was created successfully. It can take a couple
            of minutes until the process of applying your azure roles to access Cloud Control is completed.
          </div>
        </ng-template>
      </p-messages>
    </div>
    <div style="margin-top: 16px">
      <p-button (click)="toAdminPortal()">Cloud Control Admin Portal</p-button>
    </div>
  </div>
  <div *ngIf="newOrganizationFailed" class="created-text">
    <div>
      <p-messages severity="error">
        <ng-template pTemplate>
          <i class="pi pi-times-circle" style="margin-right: 5px"></i>
          <div class="m1-2">
            Your organization {{ dataFormGroup.controls.orgName.value }} could not be created. Please inform us by
            creating an issue in our github repository.
          </div>
        </ng-template>
      </p-messages>
    </div>
    <div style="margin-top: 16px">
      <p-button (click)="navigateToCommunity()">Create Issue</p-button>
    </div>
  </div>
</div>
